const months: string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
]

const splitDateElements = (date: string): number[] =>
  date.split("-").map((token) => parseInt(token))

export const formatDatePeriod = (
  startDate: string,
  endDate: string | null
): string => {
  const [startYear, startMonth, startDay] = splitDateElements(startDate)
  const startMonthText = months[startMonth - 1]

  if (!endDate) {
    return `${startMonthText} ${startDay}, ${startYear}`
  }

  const [endYear, endMonth, endDay] = splitDateElements(endDate)
  const endMonthText = months[endMonth - 1]

  if (startYear !== endYear) {
    return `${startMonthText} ${startDay}, ${startYear} - ${endMonthText} ${endDay}, ${endYear}`
  }

  if (startMonth !== endMonth) {
    return `${startMonthText} ${startDay} - ${endMonthText} ${endDay}, ${startYear}`
  }

  return `${startMonthText} ${startDay} - ${endDay}, ${startYear}`
}
