import React, { FunctionComponent } from "react"
import { useIntl } from "react-intl"
import { Header, HeaderVariant } from "../../../components/header/header"
import { HomePageHeader } from "../../home-page/components/home-page-header/home-page-header"
import { YearEndContent } from "../../home-page/home-page-types"
import { VideoHeader } from "@components/video-header/video-header"
import { SearchProvider } from "@features/search/search-provider"
import { ImageHeader } from "@components/image-header/image-header"

export type HeaderWrapperVariant = HeaderVariant | "Video" | "Image"

export interface HeaderVideoOptions {
  posterVideoId: string
  videoId: string
  heading: string
  quote: string
  attribution: string
  buttonText: string
}

export interface HeaderWrapperProps {
  isHomePage: boolean
  variant?: HeaderWrapperVariant
  videoOptions?: HeaderVideoOptions
  hideSearch: boolean
  cartIcon?: boolean
  isInfoIconShown?: boolean
  location: Location
  pageSearchIndexId: string
  yearEndContent?: YearEndContent | null
  imageSrc?: string
}

export const HeaderWrapper: FunctionComponent<HeaderWrapperProps> = ({
  variant = "white",
  videoOptions,
  hideSearch,
  isHomePage,
  cartIcon,
  isInfoIconShown,
  location,
  pageSearchIndexId,
  yearEndContent,
  imageSrc
}) => {
  const { formatMessage } = useIntl()

  const props = {
    cartIcon,
    isInfoIconShown
  }

  return (
    <SearchProvider
      location={location as Location}
      searchIndexId={pageSearchIndexId}
    >
      {isHomePage ? (
        <HomePageHeader {...props} yearEndContent={yearEndContent} />
      ) : variant === "Video" ? (
        <VideoHeader {...props} {...videoOptions} />
      ) : variant === "Image" ? (
        <ImageHeader imageSrc={imageSrc} />
      ) : (
        <Header
          searchPlaceholder={formatMessage({ id: "header-search-placeholder" })}
          variant={variant}
          hideSearch={hideSearch}
          {...props}
        />
      )}
    </SearchProvider>
  )
}
