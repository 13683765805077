import React, { FunctionComponent } from "react"
import classNames from "classnames"
import styles from "./list.module.scss"

export * from "./list-item"
export * from "./list-item-article"
export * from "./list-item-collection"
export * from "./list-item-conference"
export * from "./list-item-devotional"
export * from "./list-item-generic"
export * from "./list-item-page"
export * from "./list-item-post"
export * from "./list-item-product"
export * from "./list-item-sermon"
export * from "./list-item-teacher"
export * from "./list-item-podcast-episodes"

export type ListSize = "xs" | "sm" | "md" | "lg" | "xl" | "fluid"

export type ListVariant =
  | "generic"
  | "article"
  | "collection"
  | "conference"
  | "devotional"
  | "post"
  | "product"
  | "sermon"
  | "teacher"
  | "teaching-series"
  | "video"
  | "results"

export interface ListProps {
  className?: string
  ordered?: boolean
  size?: ListSize
  variant?: ListVariant
}

export const List: FunctionComponent<ListProps> = ({
  children,
  className,
  ordered = false,
  size = "md",
  variant = "generic"
}) => {
  const ListElement = ordered ? "ol" : "ul"
  const listClassName = classNames(
    styles.list,
    styles[size],
    variant && styles[variant],
    ordered && styles.ordered,
    className
  )

  return <ListElement className={listClassName}>{children}</ListElement>
}
