import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useEffect,
  useState
} from "react"
import ReactPlayerLoader from "@brightcove/react-player-loader"
import { VideoJsPlayer, VideoJsPlayerOptions } from "video.js"
import classNames from "classnames"
import { v4 as uuidv4 } from "uuid"
import { VideoEventProps } from "../../core/segment/types"
import { useSegmentEvent } from "../../hooks/use-segment-event"
import styles from "./video-header-main-player.module.scss"
import {
  accountId,
  playerEmbed,
  playerId
} from "@components/player/player-constants"
import { Icon } from "@components/icon/icon"

interface MainPlayerProps {
  videoId?: string
  className?: string
  onPlay: () => void
  onPause: () => void
  onEnd: () => void
  isLaunched: boolean
}

export const MainPlayer: FunctionComponent<MainPlayerProps> = ({
  className,
  videoId,
  onPlay,
  onPause,
  onEnd,
  isLaunched
}) => {
  const sessionId = useMemo(() => uuidv4(), [])

  const [isFullscreen, setIsFullscreen] = useState(false)

  const { triggerEvent } = useSegmentEvent()

  const getPlaybackData = useCallback(
    (player: VideoJsPlayer): Partial<VideoEventProps> => {
      const position = Math.floor(player.currentTime())
      const total_length = Math.floor(player.duration())
      const percent_complete = `${
        position === 0 ? 0 : Math.round((position / total_length) * 100)
      }%`

      return {
        content_asset_id: videoId,
        session_id: sessionId,
        position,
        total_length,
        percent_complete
      }
    },
    [videoId, sessionId]
  )

  const reactPlayerLoaderRef = useRef<typeof ReactPlayerLoader>(null)
  const playerRef = useRef<VideoJsPlayer | null>(null)

  // Auto-play full video on launch
  useEffect(() => {
    if (isLaunched) {
      playerRef.current?.currentTime?.(0)
      playerRef.current?.play?.()
    }
  }, [isLaunched])

  const successHandler = (player: VideoJsPlayer) => {
    playerRef.current = player

    // Only reliable way to disable controls; passing the param doesn't work
    // Need to disable controls for custom playback UI in non-fullscreen
    player.controls(false)

    if (screen.orientation) {
      screen.orientation.onchange = () => {
        const { type, angle } = screen.orientation
        const isRotated = type.includes("landscape") && angle === 90

        if (isRotated) {
          player.requestFullscreen()
        }
      }
    } else {
      // MediaQueryList fallback approach for browsers that don't support
      // screen.orientation (looking at you, Safari)
      const mql = window.matchMedia("(orientation: landscape)")

      mql.onchange = (e) => {
        if (e.matches) {
          player.requestFullscreen()
        }
      }
    }

    player.on("pause", () => {
      triggerEvent(getPlaybackData(player), "Video Paused")
      onPause()
    })

    player.on("play", () => {
      const playbackData = getPlaybackData(player)

      // If the user has not played this yet and just pressed play.
      if (player.played().length === 0) {
        triggerEvent({ ...playbackData }, "Video Started")
      }

      // The user has played this and is resuming.
      if (player.played().length) {
        triggerEvent({ ...playbackData }, "Video Resumed")
      }

      onPlay()
    })

    player.on("fullscreenchange", () => {
      const fullscreenStatus = player.isFullscreen()

      player.controls(fullscreenStatus)
      setIsFullscreen(fullscreenStatus)
    })

    player.on("ended", () => {
      const playbackData = getPlaybackData(player)

      if (player.isFullscreen()) {
        player.exitFullscreen()
      }

      triggerEvent({ ...playbackData }, "Video Completed")

      onEnd()
    })
  }

  const handlePlay = () => {
    if (!playerRef.current) return

    if (playerRef.current.paused()) {
      playerRef.current.play()
    } else {
      playerRef.current.pause()
    }
  }

  const handleFullscreen = () => {
    if (!playerRef.current) return

    if (playerRef.current.isFullscreen()) {
      playerRef.current.exitFullscreen()
    } else {
      playerRef.current.requestFullscreen()
    }
  }

  const playerOptions: VideoJsPlayerOptions = {
    playbackRates: [1, 1.25, 1.5, 1.75, 2],
    plugins: {
      seekButtons: {
        forward: 10,
        back: 10
      }
    }
  }

  const isPaused = playerRef.current?.paused()

  return (
    <div
      className={classNames(className, styles.container, {
        [styles.fullscreen]: isFullscreen
      })}
    >
      <ReactPlayerLoader
        ref={reactPlayerLoaderRef}
        accountId={accountId}
        playerId={playerId}
        embedId={playerEmbed}
        videoId={videoId}
        id={`player-${sessionId}`}
        options={{
          ...playerOptions,
          suppressNotSupportedError: true
        }}
        embedOptions={{ responsive: true }}
        onSuccess={({ ref: player }: { ref: VideoJsPlayer }) =>
          successHandler(player)
        }
        onFailure={() => {
          console.log("The video player has failed to load")
        }}
      />
      <div
        className={classNames(styles.controls, {
          [styles.forceHover]: isPaused
        })}
      >
        <div className={styles.playButton} onClick={handlePlay}>
          {isPaused ? (
            <>
              <Icon className={styles.icon51} variant="51-play-circle" />
              <Icon className={styles.icon75} variant="75-play-circle" />
            </>
          ) : (
            <>
              <Icon className={styles.icon51} variant="51-pause-circle" />
              <Icon className={styles.icon75} variant="75-pause-circle" />
            </>
          )}
        </div>
        <div className={styles.fullscreenButton} onClick={handleFullscreen}>
          <Icon className={styles.icon24} variant="24-fullscreen" />
          <Icon className={styles.icon36} variant="36-fullscreen" />
        </div>
      </div>
    </div>
  )
}
