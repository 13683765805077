import { useState } from "react"
import type { Hit } from "react-instantsearch-core"
import {
  FetchSuggestions,
  SearchResult,
  Suggestion
} from "../../../components/search-box/search-box-types"
import { noSuggestions } from "../../../components/search-box/search-box-constants"
import {
  searchClient,
  querySuggestionIndexName,
  mainSearchIndexName
} from "../search-constants"
import {
  mapAlgoliaSearchResults,
  mapAlgoliaSuggestions
} from "../mappers/algolia-mappers"

interface UseSearchSuggestions {
  suggestions: Suggestion[]
  querySuggestions: Suggestion[]
  fetchSuggestions: FetchSuggestions
  clearSuggestions(): void
}

export const useSearchSuggestions = (): UseSearchSuggestions => {
  const [suggestions, setSuggestions] = useState<Suggestion[]>(noSuggestions)
  const [querySuggestions, setQuerySuggestions] =
    useState<Suggestion[]>(noSuggestions)

  const fetchSuggestions = async (query: string): Promise<void> => {
    const response = (await searchClient.search([
      {
        indexName: mainSearchIndexName,
        params: {
          query,
          hitsPerPage: 5,
          attributesToRetrieve: [
            "type",
            "fields",
            "title",
            "primaryVariation",
            "handle"
          ],
          clickAnalytics: true
        }
      },
      {
        indexName: querySuggestionIndexName,
        params: {
          query,
          hitsPerPage: 5
        }
      }
    ])) as unknown as {
      results: [
        {
          hits: Array<Hit<SearchResult>>
          index: string
          queryID: string
        },
        {
          hits: Array<Hit<SearchResult>>
          index: string
          queryID: string
        }
      ]
    }

    const [rawSearchResults, rawSuggestions] = response.results

    if (rawSearchResults.hits.length) {
      setSuggestions(
        mapAlgoliaSearchResults({
          hits: rawSearchResults.hits,
          indexName: rawSearchResults.index,
          queryID: rawSearchResults?.queryID
        }) as Suggestion[]
      )
    }

    if (rawSuggestions.hits.length) {
      setQuerySuggestions(mapAlgoliaSuggestions(rawSuggestions))
    }
  }

  const clearSuggestions = (): void => setSuggestions(noSuggestions)

  return {
    suggestions,
    querySuggestions,
    fetchSuggestions,
    clearSuggestions
  }
}
