import React, { FunctionComponent, MouseEventHandler } from "react"
import classNames from "classnames"
import { Icon, IconVariant } from "../icon/icon"
import { ListItem, ListItemProps } from "./list-item"
import styles from "./list.module.scss"
import { Teacher } from "@src-types/algolia"
import { Avatar } from "@components/avatar/avatar"
import { AvatarList, AvatarListItemProps } from "@components/avatar/avatar-list"
import { resourceIconVariant, ResourceType } from "@src-types/resource"

export type PostTeacher = Omit<Teacher, "type" | "objectID">

export type ListItemPostType = "audio" | "article" | "video" | "qa"

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PostTeacherItems extends AvatarListItemProps {}

export interface ListItemPostProps extends ListItemProps {
  teachers: PostTeacher[]
  meta: string
  title: string
  type?: ListItemPostType
  onClick?: MouseEventHandler
  typeForIcon?: ResourceType
}

const iconVariant: Record<ListItemPostType, IconVariant> = {
  article: "12-article",
  qa: "12-article",
  video: "12-video",
  audio: "12-audio"
}

export const ListItemPost: FunctionComponent<ListItemPostProps> = ({
  teachers,
  className,
  disabled = false,
  title,
  type = "article",
  url,
  onClick,
  isHighlighted,
  typeForIcon
}) => {
  const singularAvatarProps = {
    image: teachers?.[0].fields?.image?.fields?.file?.url,
    name: teachers?.[0].fields?.shortName
  }

  const pluralAvatarItems = teachers?.map<PostTeacherItems>(({ fields }) => ({
    name: fields?.shortName,
    slug: fields?.slug,
    link: "",
    image: fields?.image?.fields?.file?.url
  }))

  const isSingularTeacher = teachers && teachers?.length === 1
  const isPluralTeacher = teachers && teachers?.length > 1

  return (
    <ListItem
      className={classNames(styles.listItemPost, className)}
      disabled={disabled}
      url={url}
      onClick={onClick}
      isHighlighted={isHighlighted}
    >
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h2 className={styles.header}>
            <span>{title}</span>
          </h2>
          <div className={styles.subheaderWrapper}>
            <div className={styles.subheader}>
              <div className={styles.avatar}>
                {isSingularTeacher && (
                  <Avatar showName size="xs" {...singularAvatarProps} />
                )}
                {isPluralTeacher && (
                  <AvatarList
                    avatars={pluralAvatarItems as PostTeacherItems[]}
                    size="xs"
                    limit={4}
                  />
                )}
              </div>
            </div>
            <Icon
              variant={
                typeForIcon
                  ? resourceIconVariant[typeForIcon]
                  : iconVariant[type]
              }
              className={styles.icon}
            />
          </div>
        </div>
      </div>
    </ListItem>
  )
}
