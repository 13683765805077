import React from "react"
import removeMd from "remove-markdown"
import {
  TeacherFields,
  Type,
  ArticleFields,
  PostFields,
  CollectionFields,
  SeriesFields,
  DevotionalFields,
  ConferenceFields,
  QuestionAnsweredFields,
  FaqFields,
  SermonFields,
  MediaResourceFields,
  Product,
  ProductCollection,
  PodcastShowFields,
  PodcastEpisodeFields,
  SearchResultTeacher,
  PageFields,
  GuideFields
} from "../../../types/algolia"
import { SearchResult } from "../../../components/search-box/search-box"
import { PathPrefix, Store, StoreCollection } from "../../../core/constants"
import {
  readTime,
  itemCount,
  messageCount,
  episodeCount
} from "../../../core/utils"
import { formatDatePeriod } from "../../../i18n/dates"
import {
  ListItemCollection,
  ListItemPost,
  ListItemSermon,
  ListItemTeacher
} from "../../../components/list/list"
import { Avatar } from "../../../components/avatar/avatar"
import { formatScriptureReference } from "../../scriptures/mappers/scripture-reference-mapper"
import { ListItemInstantSearchResult } from "../../../components/list/list-item-instant-search-result"
import { AvatarList } from "../../../components/avatar/avatar-list"
import {
  mapAvatars,
  mapTeachers
} from "../components/search-hits/search-helpers"
import buildImageUrl from "@utils/imageUrl"

const thumbUrl = (url?: string) =>
  buildImageUrl(url || "", { width: 150, quality: 70 })

export const mapTeacher = (fields: TeacherFields): SearchResult => {
  return {
    title: `${fields.shortName}`,
    image: thumbUrl(fields.image?.fields?.file.url),
    meta: removeMd(fields.shortBio || "").replace("\\", ""),
    type: "teacher",
    url: `${PathPrefix.Teachers}/${fields.slug}`,
    component: ListItemTeacher
  }
}

export const mapGuide = (fields: GuideFields): SearchResult => {
  const urlPrefix = PathPrefix.Guides
  const url = `${urlPrefix}/${fields.slug}`

  return {
    title: fields.name,
    meta: "GUIDE",
    type: "guide",
    url,
    subtitle: fields.primaryTopic?.fields?.parent.fields.name || "",
    component: ListItemInstantSearchResult
  }
}

export const mapArticle = (fields: ArticleFields): SearchResult => {
  const teachers = mapTeachers(fields.teachers)

  const urlPrefix =
    fields.articleType === "Guide" ? PathPrefix.Guides : PathPrefix.Articles
  const url = `${urlPrefix}/${fields.slug}`

  return {
    title: fields.name,
    meta: `${readTime(fields.bodyCopy)}MIN`,
    type: "article",
    url,
    image: thumbUrl(fields.headerImage?.fields?.file.url),
    subtitle:
      teachers.length === 1 ? (
        <Avatar
          image={teachers[0].image}
          name={teachers[0].shortName}
          showName
          size="xs"
        />
      ) : (
        <AvatarList size="xs" avatars={mapAvatars(teachers)} limit={4} />
      ),
    component: ListItemInstantSearchResult
  }
}

export const mapPost = (fields: PostFields): SearchResult => {
  const teachers = mapTeachers(fields.teachers as SearchResultTeacher[])

  return {
    title: fields.name || "",
    meta: `${readTime(fields.bodyCopy)}MIN`,
    type: "post",
    url: `${PathPrefix.Post}/${fields.slug}`,
    image: thumbUrl(fields.headerImage?.fields?.file.url),
    subtitle:
      teachers.length === 1 ? (
        <Avatar
          image={teachers[0].image}
          name={teachers[0].shortName}
          showName
          size="xs"
        />
      ) : (
        <AvatarList size="xs" avatars={mapAvatars(teachers)} limit={4} />
      ),
    component: ListItemInstantSearchResult
  }
}

export const mapCollection = (fields: CollectionFields): SearchResult => {
  const publicationDate = fields.officialPublicationDate
    ? formatDatePeriod(fields.officialPublicationDate, null)
    : ""

  return {
    title: fields.title,
    subtitle: publicationDate,
    meta: itemCount(fields.collectionItems),
    type: "collection",
    image: thumbUrl(fields.headerImage?.fields?.file.url),
    url: `${PathPrefix.Collections}/${fields.slug}`,
    component: ListItemCollection
  }
}

export const mapSeries = (fields: SeriesFields): SearchResult => {
  const teachers = mapTeachers(fields.teachers)

  const url =
    fields.seriesType === "Conference Messages"
      ? `${PathPrefix.Conferences}/${fields.slug}`
      : `${PathPrefix.Series}/${fields.slug}`

  return {
    title: fields.name,
    meta: messageCount(fields.mediaResources),
    image: thumbUrl(fields.image?.fields?.file.url),
    subtitle:
      teachers.length === 1 ? (
        <Avatar
          image={teachers[0].image}
          name={teachers[0].shortName}
          showName
          size="xs"
        />
      ) : (
        <AvatarList size="xs" avatars={teachers} limit={4} />
      ),
    type: "series",
    url,
    component: ListItemInstantSearchResult
  }
}

const getScripture = (fields: DevotionalFields | SermonFields): string => {
  const primaryScripture = fields.primaryScriptureReference
  const secondaryScripture = fields.secondaryScriptureReferences?.length
    ? fields.secondaryScriptureReferences[0]
    : null

  let result = ""

  if (primaryScripture && primaryScripture.start?.book) {
    result = formatScriptureReference(
      {
        start: primaryScripture.start,
        end: primaryScripture.end
      },
      () => primaryScripture.start?.book || ""
    )
  }

  if (!result && secondaryScripture && secondaryScripture.start?.book) {
    result = formatScriptureReference(
      {
        start: secondaryScripture.start,
        end: secondaryScripture.end
      },
      () => secondaryScripture.start?.book || ""
    )
  }

  return result
}

export const mapDevotional = (fields: DevotionalFields): SearchResult => {
  const scripture = getScripture(fields)

  return {
    title: fields.name,
    subtitle: scripture,
    type: "devotional",
    url: `${PathPrefix.Devotional}/${fields.slug}`,
    component: ListItemInstantSearchResult
  }
}

export const mapConference = (fields: ConferenceFields): SearchResult => {
  const teachers = mapTeachers(fields.speakers)

  return {
    title: fields.title,
    image: thumbUrl(fields.image?.fields?.file.url),
    meta: fields.startDate
      ? formatDatePeriod(fields.startDate, fields?.endDate || null)
      : "",
    type: "event",
    url: fields.url,
    subtitle: <AvatarList size="xs" avatars={mapAvatars(teachers)} limit={4} />,
    component: ListItemInstantSearchResult
  }
}

export const mapQuestion = (fields: QuestionAnsweredFields): SearchResult => {
  const teachers = mapTeachers(fields.teachers)

  return {
    title: fields.question,
    image: "",
    meta: `${readTime(fields.answer)}MIN`,
    subtitle: `${teachers[0].shortName}`,
    type: "qa",
    url: `${PathPrefix.QuestionsAnswered}/${fields.slug}`,
    component: ListItemPost,
    typeForIcon: fields.typeForIcon
  }
}

export const mapFAQ = (fields: FaqFields): SearchResult => {
  return {
    title: fields.question,
    type: "faq",
    meta: `${readTime(fields.answer)}`,
    url: `${PathPrefix.FAQs}/${fields.slug}`
  }
}

export const mapSermon = (fields: SermonFields): SearchResult => {
  const teacher = fields.teachers.length ? fields.teachers[0] : null
  const avatar = teacher ? teacher.fields.image?.fields?.file.url : ""
  const teacherName = teacher ? `${teacher.fields.shortName}` : ""

  const scripture = getScripture(fields)

  return {
    title: fields.sermonTitle,
    meta: scripture,
    type: "sermon",
    url: `${PathPrefix.Sermon}/${fields.slug}`,
    subtitle: fields.teachers.length ? (
      <Avatar image={avatar} name={teacherName} showName size="xs" />
    ) : (
      ""
    ),
    component: ListItemSermon
  }
}

export const mapPodcastShow = (fields: PodcastShowFields): SearchResult => {
  const teachers = mapTeachers(fields.hosts)

  return {
    title: fields.title,
    image: thumbUrl(fields.image?.fields?.file.url),
    meta: episodeCount(fields.episodes),
    type: "podcastShow",
    url: fields.websiteUrl || `${PathPrefix.Podcasts}/${fields.slug}`,
    subtitle:
      teachers.length === 1 ? (
        <Avatar
          image={teachers[0].image}
          name={teachers[0].shortName}
          showName
          size="xs"
        />
      ) : teachers.length ? (
        <AvatarList size="xs" avatars={mapAvatars(teachers)} limit={4} />
      ) : (
        ""
      ),
    component: ListItemInstantSearchResult
  }
}

export const mapPodcastEpisode = (
  fields: PodcastEpisodeFields
): SearchResult => {
  const teachers = mapTeachers(fields.teachers)
  const url = fields.podcastShow
    ? `${PathPrefix.Podcasts}/${fields.podcastShow.fields.slug}/${fields.slug}`
    : ""

  const seasonAndEpisodeMeta =
    (fields.season
      ? `Season ${fields.season}${fields.episode ? ", " : ""}`
      : "") + (fields.episode ? "Episode " + fields.episode : "")

  const dateMeta = fields.date ? formatDatePeriod(fields.date, null) : ""

  return {
    title: fields.title,
    image: thumbUrl(fields.podcastShow?.fields.image?.fields?.file.url),
    meta: seasonAndEpisodeMeta || dateMeta,
    type: "podcastEpisode",
    url,
    subtitle:
      teachers.length === 1 ? (
        <Avatar
          image={teachers[0].image}
          name={teachers[0].shortName}
          showName
          size="xs"
        />
      ) : teachers.length ? (
        <AvatarList size="xs" avatars={mapAvatars(teachers)} limit={4} />
      ) : (
        ""
      ),
    component: ListItemInstantSearchResult
  }
}

export const mapMediaResource = (
  fields: MediaResourceFields
): SearchResult | null => {
  if (!fields.series || !fields.series.fields?.slug || !fields.slug) {
    return null
  }

  const teachers = mapTeachers(fields.teachers)

  const getPathPrefix = (seriesType: string | undefined): string =>
    seriesType === "Conference Messages"
      ? PathPrefix.Conferences
      : PathPrefix.Series

  return {
    image: thumbUrl(fields.series.fields.image?.fields?.file.url),
    title: fields.name,
    meta: fields.series.fields.name,
    type: "mediaResource",
    subtitle:
      teachers.length === 1 ? (
        <Avatar
          image={teachers[0].image}
          name={teachers[0].shortName}
          showName
          size="xs"
        />
      ) : teachers.length ? (
        <AvatarList size="xs" avatars={mapAvatars(teachers)} limit={4} />
      ) : (
        ""
      ),
    url: `${getPathPrefix(fields.series.fields.seriesType)}/${
      fields.series.fields.slug
    }/${fields.slug}`,
    component: ListItemInstantSearchResult
  }
}

export const mapProduct = (product: Product): SearchResult => {
  const variant = product.primaryVariation || product.products?.[0] || {}
  const teachers = product.fields?.teachers

  let subtitle: string | JSX.Element = " "

  if (teachers?.length) {
    if (teachers.length === 1) {
      const teacher = teachers[0].fields

      subtitle = (
        <Avatar
          image={teacher.image?.fields?.file.url}
          name={`${teacher.shortName}`}
          showName
          size="xs"
        />
      )
    } else {
      const avatars = teachers!.map((teacher) => ({
        image: teacher.fields.image?.fields?.file.url || "",
        name: `${teacher.fields.shortName}`
      }))

      subtitle = <AvatarList avatars={avatars} size="xs" limit={4} />
    }
  }

  return {
    title: product.title || "",
    meta: variant.productFormat,
    image: thumbUrl(variant.featuredImage?.originalSrc),
    imageMeta: variant.price || "",
    type: "product",
    url: variant.handle ? `${Store}/${variant.handle}` : "",
    handle: variant.handle,
    subtitle,
    component: ListItemInstantSearchResult
  }
}

export const mapProductCollection = (
  collection: ProductCollection
): SearchResult => {
  return {
    title: collection.title || "",
    meta: "Collection",
    type: "productCollection",
    url: `${StoreCollection}/${collection.handle}`,
    subtitle: "",
    component: ListItemInstantSearchResult
  }
}

export const mapPage = (fields: PageFields): SearchResult => {
  return {
    title: fields.searchTitle,
    meta: "Page",
    image: thumbUrl(fields?.searchImageUrl),
    type: "page",
    url: fields.searchUrl,
    component: ListItemInstantSearchResult
  }
}

export const resultsMapper: Record<
  Type,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (fields: any) => SearchResult | null
> = {
  post: mapPost,
  teacher: mapTeacher,
  article: mapArticle,
  collection: mapCollection,
  series: mapSeries,
  "Conference Messages": mapSeries,
  "Sermon Series": mapSeries,
  "Teaching Series": mapSeries,
  devotional: mapDevotional,
  event: mapConference,
  mediaResource: mapMediaResource,
  qa: mapQuestion,
  faq: mapFAQ,
  sermon: mapSermon,
  product: mapProduct,
  productCollection: mapProductCollection,
  podcastShow: mapPodcastShow,
  podcastEpisode: mapPodcastEpisode,
  page: mapPage,
  guide: mapGuide
}
