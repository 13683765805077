import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { Menu } from "@headlessui/react"
import { useLocation } from "@reach/router"
import styles from "./header-auth.module.scss"
import { Link } from "@components/link/link"
import { Icon } from "@components/icon/icon"
import { accountUrl } from "@core/constants"
import { AUTH_URL, useLigonierAuth } from "@utils/ligonierAuth"

export type HeaderAuthVariant = "regular" | "homepage" | "homepage_scrolled"

export interface HeaderAuthProps {
  variant?: HeaderAuthVariant
  className?: string
  accountButtonClassName?: string
}

export const HeaderAuth: FunctionComponent<HeaderAuthProps> = ({
  variant = "regular",
  className,
  accountButtonClassName
}) => {
  const { isLoading, ...user } = useLigonierAuth(true)

  const location = useLocation()

  return (
    <div
      className={classNames(
        styles.authButtons,
        styles[variant],
        variant,
        className
      )}
    >
      {isLoading ? (
        <Icon
          className={classNames(styles.authButton, accountButtonClassName)}
          variant="40-userAvatar"
        />
      ) : user.authenticated ? (
        <Menu>
          <Menu.Button className={styles.headlessButton}>
            <Icon
              variant="40-userAvatar"
              className={classNames(styles.authButton, accountButtonClassName)}
            />
          </Menu.Button>
          <Menu.Items className={styles.dropDownMenu}>
            <Menu.Item>
              <a className={styles.items} href={`${accountUrl}/dashboard/`}>
                Account
              </a>
            </Menu.Item>
            <Menu.Item>
              <a
                className={styles.items}
                href={`${accountUrl}/dashboard/learning-library/`}
              >
                My Library
              </a>
            </Menu.Item>
            <Menu.Item>
              <Link
                className={styles.items}
                to={`${AUTH_URL}/logout?redirectUri=${location.href}&grantType=implicit_cookie`}
                target="_self"
              >
                Sign out
              </Link>
            </Menu.Item>
          </Menu.Items>
        </Menu>
      ) : (
        <Link
          className={styles.authButtonLink}
          to={`${AUTH_URL}/authorize?redirectUri=${location.href}&grantType=implicit_cookie`}
          target="_self"
        >
          <Icon
            className={classNames(styles.authButton, accountButtonClassName)}
            variant="40-userAvatar"
          />
        </Link>
      )}
    </div>
  )
}
