import { SearchResultType } from "../../types/search"
import { IconVariant12 } from "../icon/icon-types"
import { Suggestion } from "./search-box-types"

export const defaultPlaceholder = "Search for Products, Topics, and Teachers"

export const noSuggestions: Suggestion[] = []

export const iconMap: Record<SearchResultType, IconVariant12> = {
  post: "12-article",
  article: "12-article",
  collection: "12-collection",
  devotional: "12-book",
  event: "12-calendar",
  mediaResource: "12-video",
  podcastShow: "12-audio",
  podcastEpisode: "12-audio",
  product: "12-tag",
  productCollection: "12-collection",
  qa: "12-help",
  faq: "12-help",
  series: "12-video",
  "Conference Messages": "12-video",
  "Teaching Series": "12-video",
  "Sermon Series": "12-video",
  sermon: "12-video",
  teacher: "12-info",
  page: "12-page",
  guide: "12-article"
}
