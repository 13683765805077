import React, { FunctionComponent } from "react"
import Image from "gatsby-image"
import classNames from "classnames"
import styles from "./image-header.module.scss"
import { Header, HeaderProps } from "@components/header/header"
import { getFluidGatsbyImage } from "@utils/gatsbyImage"

export interface ImageHeaderProps extends HeaderProps {
  imageSrc?: string
}

export const ImageHeader: FunctionComponent<ImageHeaderProps> = ({
  imageSrc
}) => {
  const imageFluid = getFluidGatsbyImage(imageSrc || "", {
    aspectRatio: 32 / 9,
    maxWidth: 3840,
    maxHeight: 810,
    quality: 100
  })

  return imageSrc && imageFluid ? (
    <>
      <Header
        className={styles.navbar}
        variant="clear"
        searchPlaceholder="Search"
      />
      <Image
        className={classNames(styles.image, "pt-[-60px]")}
        fluid={imageFluid}
      />
    </>
  ) : null
}
