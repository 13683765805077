import React, { FunctionComponent, MouseEventHandler } from "react"
import classNames from "classnames"
import { Link } from "../link/link"
import styles from "./list.module.scss"

export interface ListItemProps {
  className?: string
  disabled?: boolean
  onClick?: MouseEventHandler
  tag?: keyof JSX.IntrinsicElements
  url?: string
  isHighlighted?: boolean
}

export const ListItem: FunctionComponent<ListItemProps> = ({
  children,
  className,
  disabled = false,
  onClick,
  tag: Tag = "li",
  url,
  isHighlighted
}) => {
  return (
    <Tag
      className={classNames(
        styles.listItem,
        {
          [styles.disabled]: disabled,
          [styles.interactive]: Boolean(onClick) || Boolean(url),
          // For manual control of highlighted style (read below)
          [styles.highlighted]: isHighlighted,
          // Prevents highlight styles from being applied on :hover when
          // isHighlighted has been passed as a boolean and is false; we can
          // assume that if isHighlighted is being passed and isn't undefined,
          // the parent context is controlling the highlighted states of list
          // items and using event listeners (i.e. Downshift is probably
          // steering the ship)
          [styles.disableHover]: isHighlighted === false
        },
        className
      )}
      onClick={disabled ? undefined : onClick}
    >
      {url ? (
        <Link className={styles.listItemContentWrapper} to={url}>
          {children}
        </Link>
      ) : (
        <div className={styles.listItemContentWrapper}>{children}</div>
      )}
    </Tag>
  )
}
