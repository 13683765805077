import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { Link } from "gatsby"
import { Button } from "../button/button"
import styles from "./header.module.scss"
import { useNewCart } from "@contexts/newCartContext"
import { StoreCart } from "@core/constants"

export interface HeaderCartProps {
  forceShow?: boolean
  cartIcon?: boolean
  className?: string
}

const HeaderCart: FunctionComponent<HeaderCartProps> = ({
  forceShow,
  cartIcon = true,
  className
}) => {
  const [{ context }] = useNewCart()

  if (!cartIcon) {
    return null
  }

  const { totalItems } = context

  if (totalItems === 0 && !forceShow) {
    return null
  }

  return (
    <Link to={StoreCart} className={styles.cartIconLink}>
      <Button
        data-cart={totalItems}
        icon="16-cart"
        className={classNames(
          styles.cartButton,
          totalItems && styles.cartCount,
          className
        )}
      />
    </Link>
  )
}

export { HeaderCart }
