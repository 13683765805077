import { useEffect } from "react"
import { LigonierAuthResponse } from "@utils/ligonierAuth"

const segmentKey = process.env.GATSBY_SEGMENT_KEY

export const useSegment = (user?: LigonierAuthResponse): void => {
  useEffect(() => {
    const analytics = window.analytics || []

    window.analytics = analytics
    const email = user?.email

    if (analytics.initialize && email) {
      analytics.page({
        email
      })

      return
    }

    if (analytics.invoked) {
      console.warn("Segment snippet included twice.")

      return
    }

    analytics.invoked = true

    analytics.methods = [
      "trackSubmit",
      "trackClick",
      "trackLink",
      "trackForm",
      "pageview",
      "identify",
      "reset",
      "group",
      "track",
      "ready",
      "alias",
      "debug",
      "page",
      "once",
      "off",
      "on",
      "addSourceMiddleware",
      "addIntegrationMiddleware",
      "setAnonymousId",
      "addDestinationMiddleware"
    ]

    analytics.factory = function (method: string) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return function (...params: any) {
        params.unshift(method)
        analytics.push(params)

        return analytics
      }
    }

    for (const key of analytics.methods) {
      analytics[key] = analytics.factory(key)
    }

    analytics.load = (key: string): void => {
      const script = document.createElement("script")

      script.type = "text/javascript"
      script.defer = true
      script.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`

      document.head.appendChild(script)
    }

    analytics.SNIPPET_VERSION = "4.1.0"
    analytics.load(segmentKey)

    if (email) {
      analytics.page({
        email
      })
    }
  }, [user])
}
