import React, { FunctionComponent, MouseEvent, useCallback } from "react"
import { FormattedMessage } from "react-intl"
import classNames from "classnames"
import { ShopifyProductHook } from "../../hooks/useShopifyProduct"
import {
  Availability,
  AvailabilityMessageMap
} from "../../features/store/product-availability-source"
import { ProductVariant } from "../../types/algolia"
import { Spinner } from "../spinner/spinner"
import styles from "./product-prices.module.scss"
import { useNewCart } from "@contexts/newCartContext"
import { showCompareAtPrice } from "@core/prices"
import { formatPrice } from "@utils/formatPrice"

export interface PriceVariant {
  compareAtPrice: string
  price: string
  storefrontId: string
}

export interface ShopifyProductAvailability {
  availability: Availability
}
export interface ProductPricesProps {
  variant: ProductVariant | PriceVariant
  onAddClick?: (id: string) => Promise<void>
  onSearchConversion?: () => void
  onClick?: (e: MouseEvent) => Promise<void>
  shopifyProduct?: ShopifyProductHook | ShopifyProductAvailability
  shortButtonMessage?: boolean
  processing?: boolean
  className?: string
  buttonClassName?: string
  showAvailabilityMessages?: boolean
}

export const ProductPrices: FunctionComponent<ProductPricesProps> = ({
  variant,
  shopifyProduct,
  onAddClick,
  onSearchConversion,
  shortButtonMessage,
  onClick,
  processing,
  className,
  buttonClassName,
  showAvailabilityMessages = true
}) => {
  const [{ context }] = useNewCart()

  // processing could come in as false so we need to check for undefined
  // to see if it's not passed as a prop. Processing should override the current
  // check for items being added.
  const itemIsBeingAdded =
    processing !== undefined
      ? processing
      : variant?.storefrontId
      ? context.itemsBeingAdded.includes(variant?.storefrontId)
      : false

  const handleAddClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault()
      onClick?.(event)
      onAddClick?.(variant.storefrontId!)
      onSearchConversion?.()
      event.stopPropagation()
    },
    [onAddClick, variant.storefrontId, onClick, onSearchConversion]
  )

  const availability = shopifyProduct?.availability ?? Availability.unknown
  const shouldEnableAddButton = [
    Availability.available,
    Availability.preorder,
    Availability.backorder
  ].includes(availability)

  const addToCartLabelId = shortButtonMessage
    ? "store-add"
    : AvailabilityMessageMap[availability]
  const buttonMessage =
    addToCartLabelId && shouldEnableAddButton ? (
      <FormattedMessage id={addToCartLabelId} />
    ) : (
      "Out of Stock"
    )

  const purchaseButtonSpinnerElements = (
    <div className={classNames(styles.addLinkSpinner, buttonClassName)}>
      <Spinner />
    </div>
  )

  const purchaseButtonElements =
    shouldEnableAddButton || showAvailabilityMessages ? (
      <span
        className={classNames(styles.addLink, buttonClassName, {
          [styles.disabled]: !shouldEnableAddButton
        })}
        onClick={handleAddClick}
      >
        {buttonMessage}
      </span>
    ) : null

  const compareAtPrice = variant.compareAtPrice || ""
  const price = variant.price || ""

  return (
    <div className={classNames(styles.prices, className)}>
      {showCompareAtPrice(price, compareAtPrice) && (
        <span className={styles.comparePrice}>
          {formatPrice(compareAtPrice)}
        </span>
      )}
      <span className={styles.price}>{formatPrice(price)}</span>

      {(onAddClick || onClick) &&
        (itemIsBeingAdded
          ? purchaseButtonSpinnerElements
          : purchaseButtonElements)}
    </div>
  )
}
