import React, { Fragment, FunctionComponent } from "react"
import classNames from "classnames"
import { SearchBox, SearchBoxProps } from "../search-box/search-box"
import { Button } from "../button/button"
import styles from "./header.module.scss"

export interface HeaderSearchProps extends SearchBoxProps {
  className?: string
  close?: () => void
  hide?: boolean
  hasBodyScrollLock?: boolean
  onFocus?: () => void
}

export const HeaderSearch: FunctionComponent<HeaderSearchProps> = ({
  close,
  className,
  hide,
  hasBodyScrollLock,
  ...props
}) =>
  hide ? null : (
    <Fragment>
      <SearchBox
        {...props}
        className={classNames(styles.search, className)}
        dropdownClassName={styles.searchDropdown}
        hasBodyScrollLock={hasBodyScrollLock}
      />
      <Button
        className={styles.closeSearchButton}
        icon="16-cross"
        onClick={close}
      />
    </Fragment>
  )
