import React, { FunctionComponent, useRef } from "react"
import ReactPlayerLoader from "@brightcove/react-player-loader"
import { VideoJsPlayer, VideoJsPlayerOptions } from "video.js"
import classNames from "classnames"
import { v4 as uuidv4 } from "uuid"
import { VideoEventProps } from "../../core/segment/types"
import styles from "./video-header-poster-player.module.scss"
import {
  accountId,
  playerEmbed,
  playerId
} from "@components/player/player-constants"
import { useSegmentEvent } from "@hooks/use-segment-event"

interface PosterPlayerProps {
  className?: string
  videoId?: string
}

const sessionId = uuidv4()

export const PosterPlayer: FunctionComponent<PosterPlayerProps> = ({
  className,
  children,
  videoId
}) => {
  const { triggerEvent } = useSegmentEvent()

  const getPlaybackData = (player: VideoJsPlayer): Partial<VideoEventProps> => {
    const position = Math.floor(player.currentTime())
    const total_length = Math.floor(player.duration())
    const percent_complete = `${
      position === 0 ? 0 : Math.round((position / total_length) * 100)
    }%`

    return {
      content_asset_id: videoId,
      session_id: sessionId,
      position,
      total_length,
      percent_complete
    }
  }

  const reactPlayerLoaderRef = useRef<typeof ReactPlayerLoader>(null)
  const playerRef = useRef<VideoJsPlayer | null>(null)

  const successHandler = (player: VideoJsPlayer) => {
    playerRef.current = player
    // Only reliable way to disable controls; passing the param doesn't work
    player.controls(false)

    // Get the TextTrackList object to use for disabling captions/subtitles
    // (they show automatically by default when the video is muted)
    const textTrackListObj = player.textTracks()

    // The only way to disable captions/subtitles completely is to set the
    // "mode" to "disabled" for each text track; this must be done on the
    // "change" event after all text tracks have been loaded and the player
    // has set one to "showing" in response to the video being muted
    textTrackListObj.addEventListener("change", () => {
      // We need to call player.textTracks() again so that we're working with
      // the up-to-date text track list
      // We also need to convert it to an array because the TextTrackList
      // type isn't iterable...
      const textTracks = Array.from(player.textTracks())

      textTracks.forEach((t) => {
        // Note that we need to check specifically for "showing" instead of
        // tracks that just have a mode other than "disabled"
        // The player seems to automatically switch some tracks to "hidden"
        // after they're set to "disabled", so finding ones that aren't
        // "disabled" and setting them to "disabled" causes infinite "change"
        // events to fire...
        if (t.mode === "showing") {
          t.mode = "disabled"
        }
      })
    })

    player.on("play", () => {
      const playbackData = getPlaybackData(player)

      if (player.played().length === 0) {
        triggerEvent({ ...playbackData }, "Video Started")
      }

      // The user has played this and is resuming.
      if (player.played().length) {
        triggerEvent({ ...playbackData }, "Video Resumed")
      }
    })

    player.on("pause", () => {
      const playbackData = getPlaybackData(player)

      triggerEvent({ ...playbackData }, "Video Paused")
    })

    player.on("timeupdate", () => {
      const playbackData = getPlaybackData(player)
      const currentPosition = playbackData.position || 0

      const loopDuration = player.duration() - 2

      // For seamless loop
      if (currentPosition > loopDuration) {
        player.currentTime(1)
      }
    })
  }

  const playerOptions: VideoJsPlayerOptions = {
    autoplay: true,
    muted: true
  }

  return (
    <div className={classNames(className, styles.container)}>
      <ReactPlayerLoader
        ref={reactPlayerLoaderRef}
        accountId={accountId}
        playerId={playerId}
        embedId={playerEmbed}
        videoId={videoId}
        id={`player-${sessionId}`}
        options={{
          ...playerOptions,
          suppressNotSupportedError: true
        }}
        embedOptions={{ responsive: true }}
        onSuccess={({ ref: player }: { ref: VideoJsPlayer }) =>
          successHandler(player)
        }
        onFailure={() => {
          console.log("The video player has failed to load")
        }}
      />
      <div className={styles.tint}>{children}</div>
    </div>
  )
}
